.main-slider {
    --slider-height-percentage: 38%;
    @media only screen and (max-width: 880px) {
        --slider-height-percentage: 60%;
    }
    @media only screen and (max-width: 580px) {
        --slider-height-percentage: 90%;
    }
    --slider-transition-duration: 770ms;
    --organic-arrow-thickness: 4px;
    --organic-arrow-border-radius: 0px;
    --organic-arrow-height: 40px;
    --organic-arrow-color: #e9e8eb;
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-background: transparent;
    --control-bullet-color: #05A7E2;
    --control-bullet-active-color: #005FB9;
    --loader-bar-color: #05A7E2;
    --loader-bar-height: 3px;
    .awssld__bullets {
        button {
            height: 12px;
            width: 12px;
        }
    }
}
.captioned {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.2);
    .container {
        width: 100%;
        padding: 0 15%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        @media only screen and (max-width: 620px) {
            padding: 0 0;
        }
        .header {
            font-weight: 400;
            font-size: 3.3rem;
            text-shadow: 0px 2px 5px rgba(0,0,0,0.5);
            @media only screen and (max-width: 1000px) {
                font-size: 2.2em;
            }
            @media only screen and (max-width: 620px) {
                font-size: 1.8em;
            }
            &.white {
                color: white;
            }
        }
        .ui.huge.button {
            @media only screen and (max-width: 1000px) {
                font-size: 1.25rem !important;
            }
            @media only screen and (max-width: 620px) {
                font-size: 1.2rem !important;
            }
        }
        .whatsapp {
            font-size: 1.8rem;
        }
    }
}
