.testimonials-slider {
    --content-background-color: white;
    --slider-height-percentage: 56%;
    @media only screen and (max-width: 450px) {
        --slider-height-percentage: 80%;
    }
    @media only screen and (max-width: 350px) {
        --slider-height-percentage: 90%;
    }
    --slider-transition-duration: 770ms;
    --organic-arrow-thickness: 4px;
    --organic-arrow-border-radius: 0px;
    --organic-arrow-height: 25px;
    --organic-arrow-color: #e9e8eb;
    --control-button-width: 10%;
    --control-button-height: 90%;
    --control-button-background: transparent;
    --control-bullet-color: #05A7E2;
    --control-bullet-active-color: #005FB9;
    --loader-bar-color: #05A7E2;
    --loader-bar-height: 3px;
    .slide-container {
        width: 100%;
        height: 100%;
    }
    .awssld__bullets {
        bottom: 5px;
        z-index: 10000;
        button {
            height: 8px;
            width: 8px;
        }
    }
    .awssld__controls {
        .awssld__next, .awssld__prev {
            top: 15%;
        }
    }
}
.testimonials {
    .testimonial-container {
        .testimonial {
            height: 100%;
            color: #4A4A4A;
            padding: unset;
            .comment {
                // padding-top: .5rem;
                font-weight: 100;
                //font-size: 1.2rem;
                font-size: 18px;
                // font-size: 3.5vw;
                margin: 0 0 .6em;
            }
            .date {
                color: #999999;
                font-weight: 400;
                padding-bottom: 2.5px;
                // font-size: 3vw;
                margin: 0 0 .1em;
            }
            .name {
                font-weight: 600;
                font-size: 20px;
                // font-size: 3.3vw;
                padding: 2.5px 0 2.5px;
                margin: 0 0 .4em;
            }

            @media only screen and (min-width: 991px) and (max-width: 1199px) {
                .comment {
                    // font-size: 1.1rem;
                    font-size: 16px;
                }
                img.image {
                    width: 4.8rem;
                }
             }
             @media only screen and (min-width: 768px) and (max-width: 991px) {
                img.image {
                    display: none;
                }
                .comment {
                    // font-size: 1.1rem;
                    font-size: 16px;
                }
             }
            @media only screen and (max-width: 768px) {
                .comment {
                    //font-size: 2rem;
                    font-size: 18px;
                }
                .name {
                    font-size: 20px;
                }
                img.image {
                    width: 7rem;
                }
            }
            @media only screen and (max-width: 669px) {
                img.image {
                    width: 5rem;
                }
                .name {
                    //font-size: 1rem;
                    font-size: 18px;
                }
                .comment {
                    //font-size: 1.6rem;
                    font-size: 20px;
                }
            }
        }
    }
}
