.menu.social-media {
    i {
        font-size: 1.8rem;
        color: #9a9a9a;
        &.whatsapp {
            font-size: 2.1rem;
            line-height: 1.1;
        }
    }
    a:hover i {
        &.facebook {
            color: #1778f2;
        }
        &.twitter {
            color: #1da1f2;
        }
        &.instagram {
            color: #e1306c;
        }
        &.youtube {
            color: #ff0000;
        }
        &.whatsapp {
            color: #25d366;
        }
    }
}
