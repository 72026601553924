@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,900;1,700&display=swap');
@font-face {
    font-family: 'CaflischScript';
    src: url('../fonts/CaflischScriptPro-Regular.eot');
    src: url('../fonts/CaflischScriptPro-Regular.eot') format('embedded-opentype'),
         url('../fonts/CaflischScriptPro-Regular.woff2') format('woff2'),
         url('../fonts/CaflischScriptPro-Regular.woff') format('woff'),
         url('../fonts/CaflischScriptPro-Regular.ttf') format('truetype'),
         url('../fonts/CaflischScriptPro-Regular.svg#CaflischScriptPro-Regular') format('svg');
}
@font-face {
    font-family: 'DentalIcons2';
    src: url('../fonts/dental-icons/dentalicons2.eot');
    src: url('../fonts/dental-icons/dentalicons2.eot') format('embedded-opentype'),
         url('../fonts/dental-icons/dentalicons2.woff') format('woff'),
         url('../fonts/dental-icons/dentalicons2.ttf') format('truetype'),
         url('../fonts/dental-icons/dentalicons2.svg#dentalicons2') format('svg');
}
@font-face {
    font-family: 'DentalIcons3';
    src: url('../fonts/dental-icons/dental-icons-3.eot');
    src: url('../fonts/dental-icons/dental-icons-3.eot') format('embedded-opentype'),
         url('../fonts/dental-icons/dental-icons-3.woff') format('woff'),
         url('../fonts/dental-icons/dental-icons-3.woff2') format('woff2'),
         url('../fonts/dental-icons/dental-icons-3.ttf') format('truetype'),
         url('../fonts/dental-icons/dental-icons-3.svg#dental-icons-3') format('svg');
}
@font-face {
    font-family: 'DentalIcons4';
    src: url('../fonts/dental-icons/dental-icons-4.eot');
    src: url('../fonts/dental-icons/dental-icons-4.eot') format('embedded-opentype'),
         url('../fonts/dental-icons/dental-icons-4.woff') format('woff'),
         url('../fonts/dental-icons/dental-icons-4.woff2') format('woff2'),
         url('../fonts/dental-icons/dental-icons-4.ttf') format('truetype'),
         url('../fonts/dental-icons/dental-icons-4.svg#dental-icons-4') format('svg');
}
@font-face {
    font-family: 'DentalIcons5';
    src: url('../fonts/dental-icons/dental-icons-5.eot');
    src: url('../fonts/dental-icons/dental-icons-5.eot') format('embedded-opentype'),
         url('../fonts/dental-icons/dental-icons-5.woff') format('woff'),
         url('../fonts/dental-icons/dental-icons-5.woff2') format('woff2'),
         url('../fonts/dental-icons/dental-icons-5.ttf') format('truetype'),
         url('../fonts/dental-icons/dental-icons-5.svg#dental-icons-5') format('svg');
}
