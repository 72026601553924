@import 'social-media';
@import 'fonts';

$spotify-color: #1bd75f;
$footer-background-color: #005FB9;
$bottom-footer-background-color: #003F7B;
$primary-color: #005FB9;
$secondary-color: #05A7E2;

p {
  font-family: "Source Sans Pro", sans-serif;
}

h1, h2, h3, h4 {
  &.ui.header {
      color: $primary-color;
      font-weight: 200;
      font-family: "Source Sans Pro", sans-serif;
  }
}
.ui.container.section {
    padding: 2rem 0;
    .header{
        font-size: 50px;
    }
    .section-description {
        font-weight: 200;
        font-family: "Source Sans Pro", sans-serif;
        font-size: 20px;
    }
    &.contact-form {
        .form-header {
            font-size: 20px;
            color: #7B7A7A;
            font-weight: 600;
        }
        button {
            color: white;
            background-color: $secondary-color;
            &:hover {
                background-color: $primary-color;
            }
        }
    }
    .commitments {
        padding-top: 2rem;
        .column.commitment {
            .label.circular {
                color: white;
                font-size: 4.5rem;
                font-weight: 100;
                background-color: $primary-color;
                &.professional {
                    font-family: DentalIcons5;
                }
                &.technology {
                    font-family: DentalIcons4;
                }
                &.no-pain {
                    font-family: DentalIcons5;
                }
                &.costs {
                    font-family: DentalIcons5;
                }
            }
            p {
                font-weight: 200;
                font-size: 1.5rem;
                padding-top: .65rem;
            }
        }
    }
}
.ui {
  &.segment {
    &.app-container {
        padding: unset;
        margin: unset;
        border: 0;
    }
    &.header {
        padding: .5em 2em;
        margin-bottom: 0px;
        border-bottom: 1px solid #e4e4e4;
        @media only screen and (max-width: 991px) {
            padding: .5em 0;
        }
        .ui.secondary.pointing.menu {
            border-width: 0px;
            .item {
                align-self: center;
                &.header-icon {
                    @media only screen and (max-width: 991px) {
                        display: block;
                        width: 100%;
                        height: 100%;
                        text-align: center;
                    }
                    img {
                        width: 120px;
                        height: 60px;
                    }
                    @media only screen and (min-width: 991px) {
                        padding-bottom: 0;
                        padding-top: 0;
                    }
                    @media only screen and (max-width: 991px) {
                        padding-bottom: 8px;
                        padding-top: 8px;
                    }
                    span.trotm {
                        font-size: 2.5rem;
                    }
                }
            }
        }
    }
    &.footer.placeholder {
      background-color: $footer-background-color;
      .column {
        &.contact-form .form-container{
            padding-bottom: 1.25rem;
            .field {
                max-width: unset;
          }
        }
        .header {
          font-weight: 200;
          margin-bottom: 30px;
          font-size: 35px;
        }
        p {
          color: #FFF;
          font-size: 16px;
          line-height: 24px;
          font-weight: 300;
        }
        &.abstract-about {
          p {
            margin-bottom: 30px;
            width: 90%;
          }
        }
        a.text-link {
          color: #CC102D;
        }
        &.social-media-container {
            .social-media.menu i {
                color: white;
            }
        }
      }
    }
    &.main-container.basic {
        padding: unset;
        background-color: white;
        .main-wrapper {
            padding: 0 6em 10em;
            &.shade {
                border-top: unset !important;
                border-bottom: 0;
                &.black {
                    //background-color: rgba(0, 0, 0, 0.7);
                }
            }
        }
    }
    &.footer, &.main-container.basic {
      margin: unset;
      .main-wrapper {
          @media only screen and (max-width: 991px) {
              padding: 1em 1em 10em;
          }
      }
    }
    &.cookies-container {
        left: 0;
        bottom: 0;
        width: 100%;
        position: fixed;
        z-index: 1000;
        margin: unset;
        .header {
            margin-bottom: 0;
        }
        .column {
            &.disclaimer-content {
                padding-left: 4rem!important;
                @media only screen and (max-width: 767px) {
                    padding-left:2.9rem!important;
                }
                .text-link {
                    color: white;
                    text-decoration: underline;
                }
            }
            @media only screen and (max-width: 767px) {
                &.button-container {
                    padding-left: 2.9rem!important;
                }
            }
        }
    }
    &.bottom-footer {
        margin: unset;
        color: #FFF;
        background-color: $bottom-footer-background-color;
        .row.content {
            padding: 0;
            a {
                color: white;
                font-weight: 600;
                padding: 0 0.2rem;
            }
            .copy-right {
                padding-left: 3.5rem;
            }
        }
    }
  }
  &.menu {
      &.sidebar.mobile-sidebar{
          .item.menu-close {
              &:hover {
                  background-color: unset;
              }
              i.close.icon {
                  margin-right: 10px !important;
              }
          }
      }
      &.justify-left {
          justify-content: left;
      }
      &.justify-right {
          justify-content: left;
      }
      &.remove-bottom-space {
          margin-bottom: 0;
      }
  }
  &.button {

  }
  &.modal {
      &.spotify-modal, &.thankyou-modal {
          .content {
              p {
                font-size: 1.5em;
              }
              .avatar-container {
                  margin-bottom: 2em;
                  img.ui.image {
                      margin-right: 10px;
                  }
                  i.icon {
                      font-size: 5.5em;
                      color: $spotify-color
                  }
                  .text.container {
                      width: auto;
                      display: inline-block;
                      font-size: 4.5em;
                  }
              }
          }
          .actions {
              border-top: none;
          }
          .description.privacy-container {
              padding: 2.5rem 1rem;
              a {
                  color: rgba(255,255,255,.6);
                  font-weight: bold;
                  font-size: 0.9em;
                  &:hover {
                      color: white;
                  }
              }
          }
      }
  }
  &.container {
      &.privacy-container {
          padding-top: 4rem;
          padding-bottom: 4rem;
          color: gray;
          a {
              color: gray;
              font-weight: bold;
          }
          .header {
              color: black;
          }
      }
  }
}
.form-field-validation {
    display: none;
}
.awssld.main-slider {
    margin-bottom: 4em;
}

.team-member {
    color: #4A4A4A;
    .ui.image {
        width: 225px;
    }
    .name {
        font-weight: 600;
        font-size: 20px;
        padding: 5px 0 2.5px;
        margin: unset;
    }
    .position {
        font-weight: 300;
    }
}
.services.section {
    .column {
        .ui.card {
            .content {
                min-height: 230px;
                @media only screen and (max-width: 992px){
                    min-height: 290px;
                }
                @media only screen and (max-width: 767px){
                    min-height: unset;
                }
                .description {
                    text-align: justify;
                }
            }
        }
    }
}
